import React from "react";
import styled from "styled-components";
import footer from "../../assets/images/footer.png";
import GroupFooter from "../../assets/images/GroupFooter.svg";
import linkedin1 from "../../assets/images/linkedin1.svg";
import facebook1 from "../../assets/images/facebook1.svg";
import instagram1 from "../../assets/images/instagram1.svg";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import location from "../../assets/images/location.svg";
import phone from "../../assets/images/phone.svg";
import email from "../../assets/images/email.svg";
import Copyright from "../../common/footer/Copyright";
import { Link } from "react-router-dom";
const StyledInnerLayouts = styled.section`
  padding: 4rem 2rem;
`;

const Footer = () => {
  return (
    <div>
      <StyledFooter>
        <StyledInnerLayouts>
          <div className="grid-cont">
            <Grid container gap={5}>
              <Grid item md={3}>
                <div className="footer-cont">
                  <div className="topFooter">
                    <div>
                      <img src={GroupFooter} alt="" />
                    </div>
                    <div>
                      <p>
                        XperLabs is a software company that is providing
                        software solutions all over the world. We are a team of
                        highly skilled and experienced professionals.
                      </p>
                    </div>
                    <div className="social-icons">
                      <a
                        href="https://www.linkedin.com/company/xperlabs/"
                        target="_blank"
                      >
                        {" "}
                        <img
                          style={{ paddingRight: "40px" }}
                          src={linkedin1}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/xperLabs"
                        target="_blank"
                      >
                        {" "}
                        <img
                          style={{ paddingRight: "40px" }}
                          src={facebook1}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://instagram.com/xper_labs?igshid=YmMyMTA2M2Y="
                        target="_blank"
                      >
                        {" "}
                        <img
                          style={{ paddingRight: "40px" }}
                          src={instagram1}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={2}>
                <Typography variant="h1" color="#ffffff" fontSize="24px">
                  Explore
                </Typography>
                <div className="links-cont">
                  <Link to="/">
                    <h1>Home</h1>
                  </Link>
                  <Link to="/about-us">
                    <h1>About</h1>
                  </Link>
                  <h1
                    style={{
                      cursor: "no-drop",
                    }}
                  >
                    Privacy Policy
                  </h1>
                  <h1
                    style={{
                      cursor: "no-drop",
                    }}
                  >
                    Terms & Conditions
                  </h1>
                </div>
              </Grid>
              <Grid item md={2}>
                <Typography variant="h1" color="#ffffff" fontSize="24px">
                  Quick links
                </Typography>
                <div className="links-cont">
                  <Link to="/contact">
                    <h1>Contact us</h1>
                  </Link>
                </div>
              </Grid>
              <Grid item md={3}>
                <Typography variant="h1" color="#ffffff" fontSize="24px">
                  <div style={{ marginBottom: "30px" }}> Get in Touch</div>
                </Typography>

                <div className="links-cont1">
                  <img
                    style={{ paddingRight: "20px" }}
                    src={location}
                    className="location-image"
                  />
                  <p
                    style={{
                      margin: "0px",
                      width: "300px",
                      color: "#ffffff",
                      marginLeft: "4px",
                    }}
                  >
                    <a
                      href="https://goo.gl/maps/jJEMArKHk9y5kH9j9"
                      target="blank"
                    >
                      A-11, Block C, <br></br>Gulshan e Jamal, Karachi. <br />{" "}
                      (opposite Mellinium Mall)
                    </a>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "60px",
                  }}
                  className="links-cont1"
                >
                  <img
                    style={{ paddingRight: "20px" }}
                    src={phone}
                    className="phone-image"
                  />
                  <p
                    style={{
                      margin: "0px",
                      width: "300px",
                      color: "#ffffff",
                      marginLeft: "4px",
                    }}
                  >
                    <a href="tel:+923122956317">+92 312 2956317</a>
                  </p>
                </div>
                <div className="links-cont1">
                  <img
                    style={{ paddingRight: "20px" }}
                    src={email}
                    className="email-image"
                  />
                  <p
                    style={{
                      margin: "0px",
                      width: "300px",
                      color: "#ffffff",
                      marginLeft: "4px",
                    }}
                  >
                    <a href="mailto:contact@xperlabs.com">
                      contact@xperlabs.com
                    </a>
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </StyledInnerLayouts>
      </StyledFooter>
      <Copyright></Copyright>
    </div>
  );
};

const StyledFooter = styled.section`
  width: 100%;
  height: 500px;
  background-image: url(${footer});
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 900px) {
    height: 600px;
  }
  @media only screen and (max-width: 675px) {
    height: 900px;
  }
  @media only screen and (max-width: 400px) {
    height: 1050px;
  }

  .grid-cont {
    padding-top: 2rem;
    padding-left: 1rem;
    .links-cont {
      padding-top: 2rem;
      padding-left: 1rem;

      h1 {
        font-size: 14px;
        color: #f6f6f6;
      }
    }
    .links-cont1 {
      width: 100%;
      display: flex;
      //   border:1px red solid;
      //   justify-content:center;
      align-items: center;
      height: 80px;
    }
  }

  .footer-cont {
    display: flex;
    @media only screen and (max-width: 600px) {
      display: block;
    }
  }

  .location-image {
    filter: invert(100%) sepia(7%) saturate(7377%) hue-rotate(298deg)
      brightness(123%) contrast(99%);
  }

  .phone-image {
    filter: invert(100%) sepia(7%) saturate(7377%) hue-rotate(298deg)
      brightness(123%) contrast(99%);
  }

  .email-image {
    filter: invert(100%) sepia(7%) saturate(7377%) hue-rotate(298deg)
      brightness(123%) contrast(99%);
  }
  .topFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    @media only screen and (max-width: 600px) {
      display: block;
    }

    .social-icons {
      padding-top: 2rem;
      @media only screen and (max-width: 600px) {
        padding-left: 1rem;
        img {
          padding: 1rem;
        }
      }
    }
  }
`;

export default Footer;
