import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Location from "../../assets/images/location.png";
const AnyReactComponent = ({ text }) => (
  <div>
    <img width="30px" src={text}></img>
  </div>
);
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 24.90057,
      lng: 67.11286,
    },
    zoom: 11,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      // bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
      <div className="google-map-style-1">
        {/* <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyB53v_rdmTRQm8y9Iqa-g9h-2_qO0G6Fuo" }}


          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={24.90057}
            lng={67.11286}
            text={Location}
          />
        </GoogleMapReact> */}
        <iframe
          src={
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3618.924343719906!2d67.11067391508287!3d24.900562149781365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb3393298dc30f1%3A0x5528cc9e01e8d91a!2sXperLabs!5e0!3m2!1sen!2s!4v1662206145022!5m2!1sen!2s"
          }
          title="Address"
          width="100%"
          height="100%"
          style={{ border: "0", borderRadius: "8px" }}
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
