import React from "react";
import SEO from "../common/SEO";

import HeaderOne from "../common/header/HeaderOne";
import HeaderTopBar from "../common/header/HeaderTopBar";
import Footer from "../components/footer/Footer";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import ServiceFour from "../elements/service/ServiceFour";
import AdvanceTabOne from "../elements/advancetab/AdvanceTabOne";
import AdvancePricingOne from "../elements/advancePricing/AdvancePricingOne";
import BrandTwo from "../elements/brand/BrandTwo";

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceOne from "../elements/service/ServiceOne";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import FooterThree from "../common/footer/FooterThree";
import { LaptopAnimation } from "../components/laptopAnimation/laptopAnimation";

export default function HomePage() {
  return (
    <>
      <SEO title="Marketing" />
      <main className="page-wrapper">
        <HeaderTopBar />
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
          <div className="container">
            <div className="banner-circle-1"></div>
            <div className="banner-circle-2"></div>
            <div className="row">
              <div className="col-lg-12  order-3 order-lg-1 mt_md--50 mt_sm--40">
                <div className="inner text-center">
                  <h1 className="title theme-gradient display-two">
                    A Better Way <br /> To{" "}
                    <Typed
                      strings={["Innovate.", "Design.", "Develop."]}
                      typeSpeed={110}
                      backSpeed={20}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    We help our clients succeed by creating brand identities,
                    digital experiences, and helping achieve technical
                    advancement.
                  </p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium round btn-icon"
                      to="/about-us"
                    >
                      Explore Now
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="contact"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-5 order-1 order-lg-2">
                <div className="thumbnail">
                  //<img src="./images/bg/bg-image-16.png" alt="Banner Images" />
                  <LaptopAnimation />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapBottom pt--100">
          <div className="container">
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-advance-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="find solution for your bussiness"
                  title="Develop your product with XperLabs"
                  description="XperLabs is a software company that is providing software solutions all over the world. We are a team of highly skilled and experienced professionals."
                />
              </div>
            </div>
            <AdvanceTabOne />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* testimonial Area  */}
        <div className="rwt-elements-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedbacks"
                  title="Testimonial."
                />
              </div>
            </div>

            <TestimonialThree teamStyle="" />
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        {/* Timeline */}
        <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Management Cycle"
                  title="Working Process."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1 mt--50">
                <TimelineTwo classVar="" />
              </div>
            </div>
          </div>
        </div>
        <Separator />

        {/* Start Brand Area  */}
        {/* <div className="rwt-brand-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Awesome Clients"
                  title="Join over +10,000 happy clients!"
                  description=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandTwo brandStyle="brand-style-2" />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Brand Area  */}
        <Footer></Footer>
        {/* <FooterThree /> */}
      </main>
    </>
  );
}
