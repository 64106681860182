import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import WebApp from "../../assets/images/web app.png";
import MobileApp from "../../assets/images/3593965.jpg";
import Digital from "../../assets/images/People working in data control center flat vector illustration.jpg";

const AdvanceTabOne = () => {
  return (
    <div>
      <Tabs>
        <div className="row row--30">
          <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
            <div className="advance-tab-button advance-tab-button-1">
              <TabList className="tab-button-list">
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Web Development</h4>
                    <p className="description">
                      Website creates a huge impact on your business, as
                      technology is evolving.
                    </p>
                  </div>
                </Tab>
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Digital Marketing</h4>
                    <p className="description">
                      Digital marketing services help market or advertise your
                      business online.
                    </p>
                  </div>
                </Tab>
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Mobile App Development</h4>
                    <p className="description">
                      Mobile apps are truly reliable for your business and
                      increases productivity.{" "}
                    </p>
                  </div>
                </Tab>
              </TabList>
            </div>
          </div>
          <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="advance-tab-content advance-tab-content-1">
              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src={WebApp} alt="Corporate Images" />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src={Digital} alt="Corporate Images" />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src={MobileApp} alt="Corporate Images" />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};
export default AdvanceTabOne;
